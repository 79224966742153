@import 'src/styles/constants';

.container {
  display: grid!important;
  grid-template-columns: 3fr 2fr;
  gap: 4px;
  padding: 0 0 1px 0;

  .time {
    text-align: center;
    color: $colors-primary-foreground;
    outline: none;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    width: 100%;

    &:hover {
      border-color: $colors-primary-foreground;
    }

    &:focus {
      border-color: $colors-primary;
    }
  }
}

.form {
  margin: 8px 0;
  gap: 8px 24px;
  display: grid;
  grid-template-columns: repeat(4, 4fr);

  .label {
    line-height: 24px;
  }

  .field {
    display: flex;
    gap: 8px;
    align-items: center;

    :nth-child(2) {
      flex: 1;
    }


    .resetButton {
      display: flex;
      gap: 4px;
      align-items: center;
      flex: 0 1 auto;
      min-width: 0;
      width: auto;
      padding: 0 8px;
      margin-left: -6px;
    }
  }
}

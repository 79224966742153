@import "../../styles/constants";

.container {
  display: flex;
  min-width: max-content;
  height: 100%;

  &.loading {
    pointer-events: none;
    opacity: 0.25;
  }

  .menu {
    flex-shrink: 0;
    width: 160px;

    ul {
      padding: 0;
      height: 100%;
      border-right: 1px solid rgba(0, 0, 0, 0.15);

      li.menuItem {
        display: block;
        width: 100%;
        list-style: none;
        padding: 10px;
        cursor: pointer;
        font-size: $small-font-size;
        font-weight: bold;
        text-transform: uppercase;

        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-left: 4px solid rgba(0, 0, 0, 0.15);

        &.disabled {
          opacity: 0.35;
        }

        &.active:not(.disabled) {
          border-left-color: $colors-primary;
          color: $colors-primary;
        }

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .content {
    flex: 1;

    width: 600px;

    .buttons {
      height: 60px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
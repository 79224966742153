.container {
  display: grid;
  grid-template-columns: 0fr 2fr 5fr 5fr;
  align-items: center;
  grid-gap: 4px;
  margin: 2px 0;

  .input {
    text-align: right;
  }
}
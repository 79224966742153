@import '../../../../styles/constants';
@import '../../../../styles/common';

.down {
  color: $colors-danger-text;
}

.overtime {
  color: $colors-accent-light;
}

.unknown {
  color: $colors-secondary-text;
}

.on {
  color: $colors-success-text;
}

.off {
  color: $colors-secondary-text;
}

.container {
  display: flex;
  align-items: center;
  padding: 0 4px;
  gap: 4px;
  line-height: $default-row-height;

  i, span {
    display: block;
    padding: 0;
    line-height: $default-row-height;
  }
}

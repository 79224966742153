.inactive td:not(first-child) {
  color: rgba(0, 0, 0, 0.45);
}

.usersTable {
  border-collapse: collapse;

  tr {
    td {
      font-size: 13px;
      font-weight: 400;

      padding: 4px;

      span {
        font-weight: inherit;

        &.group {
          padding: 0 4px;
        }
      }
    }
  }
}

.connectionStatus {
  color: crimson;

  i {
    display: block;
    text-align: center;
    font-size: 10px;
    color: inherit;
  }

  .active {
    color: #499472;
  }
}

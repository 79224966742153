.deleted {
  position: relative;
  td {
    opacity: 0.5;
  }

  &::after {
    content: '';
    opacity: 1;
    position: absolute;
    top: 50%;
    height: 1px;
    background: black;
    right: 4px;
    left: 4px;
  }
}

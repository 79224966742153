@import '../../styles/constants';

.container {
  .status {
    display: flex;
    border-radius: 4px;
    margin: 8px 0;
    padding: 2px;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    gap: 10px;

    .connectionIndicator {
      color: $colors-primary-foreground-light;
      padding: 0 16px;
      border-radius: 100px;
      background-color: $colors-danger-text;

      .connectionIndicatorLabel {
        font-weight: 500;
      }
    }

    &.connected {
      .connectionIndicator {
        background-color: $colors-success-text;
      }
    }

    .lastUpdate {
      display: flex;
      gap: 10px;
      color: $colors-primary-text;
      margin: 4px 0;

      .label {
      }

      .value {
      }
    }
  }

  .totals {
    display: flex;
    margin: 0 0 16px 0;
  }
}
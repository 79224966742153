@font-face {
    font-family: 'Roboto';
    font-weight: 200;
    src: url('assets/fonts/Roboto-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: url('assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: url('assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: url('assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 800;
    src: url('assets/fonts/Roboto-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: url('assets/fonts/Roboto-Black.ttf') format('truetype');
}

* {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    margin: 0;
}

html,
body {
    margin: 0;
    height: 100%;
}

#root {
    overflow: auto;
    height: 100%;
    padding: 0;
}

@import "src/styles/constants";
@import "src/styles/common";

.container {
  @include matchParent;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFFd4;

  .spinner {
    width: 48px;
    height: 48px;

    border-radius: 24px;
    border-width: 6px;
    border-style: solid;
    border-color: $colors-primary $colors-primary $colors-grid-separators $colors-grid-separators;

    animation: spin 1s infinite linear;
  }
}

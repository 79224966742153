@import '../styles/constants';

.container {
  display: inline-block;
  width: $default-modal-width;

  .content {
    .title {
      display: flex;

      .titleText {
        padding: 8px;
      }
    }

    .message {
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      margin: 8px 0 16px 0;
    }

    .icon {
      font-size: 64px;
    }

    .error {
      max-width: 300px;
      margin: 4px 0;
      padding: 12px;
      background-color: #f1f1f1;
      border-radius: 8px;
      font-size: 12px;
      font-family: monospace;
      font-weight: 700;
      white-space: pre-wrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .buttons {
    width: 100%;
    text-align: right;

    :not(:first-child) {
      margin: 0 0 0 8px;
    }
  }
}

@import 'src/styles/constants';

.expandButton {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 12px;
    display: block;
    cursor: pointer;

    &:hover {
      color: $colors-primary;
    }
  }
}

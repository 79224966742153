@use 'sass:math';
@import '../../styles/common';
@import '../../styles/constants';

.container {
  display: flex;
  align-items: center;
  outline: 1px solid blue;

  button {
    border: none;
    width: $default-row-height;
    line-height: $default-row-height;
    padding: 0;
    margin: 0 2px;
    color: $colors-primary-text;
    background: none;
    cursor: pointer;

    &:hover {
      color: $colors-primary;
    }

    i {
      font-size: $default-row-height - 8;
      line-height: inherit;
    }
  }

  .spinner {
    position: relative;
    width: $default-row-height - 8;
    height: $default-row-height - 8;
    margin: 0 2px;

    &.spinning {
      &::after {
        position: absolute;
        box-sizing: border-box;

        width: $default-row-height - 8;
        height: $default-row-height - 8;
        top: 0;
        left: 0;

        content: '';
        border: 2px solid $colors-primary;
        border-left-color: rgba(0, 0, 0, 0.05);
        border-top-color: rgba(0, 0, 0, 0.05);

        animation: spin 1s infinite linear;

        border-radius: math.div($default-row-height - 8, 2);
      }
    }
  }
}

@import 'src/styles/constants';

.numberInputCell {
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  background-color: transparent;

  &.flashing {
    animation: flash 0.6s infinite linear;
  }
}

@keyframes flash {
  0% {
    color: $colors-primary;
    background-color: rgba($colors-primary-light, 0.15)
  }

  100% {
    color: initial;
    background-color: transparent;
  }
}
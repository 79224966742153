.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0;

  border-top: 1px dashed #e8e8e8;

  .field {
    cursor: default;
    border-top: 1px dashed;
    border-right: 1px dashed;
    border-color: #e8e8e8;
    padding: 2px;

    &:nth-child(-n + 4) {
      border-top: none;
      padding-top: 12px;
    }

    &:nth-last-child(-n + 4) {
      padding-bottom: 12px;
    }

    &:nth-child(4n + 1) {
      padding-left: 12px;
    }

    &:nth-child(4n) {
      border-right: none;
      padding-right: 12px;
    }

    &:hover {
      background-color: #f8f8f8;
    }

    .label {
      font-size: small;
      color: #888;
      padding: 1px 1px 0 1px;
    }

    .value {
      font-size: small;
      padding: 0 1px 1px 1px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.number {
        text-align: right;
      }

      .na {
        color: #aaa;
      }
    }
  }
}

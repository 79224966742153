@import "../../styles/common";
@import "../../styles/constants";

.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  flex: 1 0;

  .loadingSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.80);

    &::after {
      content: '';
      width: 48px;
      height: 48px;
      border: 6px solid rgba(0, 0, 0, 0.15);
      border-top-color: $colors-primary;
      border-left-color: $colors-primary;
      border-radius: 48px;

      animation: spin 1s infinite linear;
    }
  }

  &.headless {
    border-radius: 0;

    .body {
      border: none;
    }
  }


  td .body {
    overflow-y: visible;
  }

  .body {
    flex: 1;
    position: relative;
    overflow-y: scroll;

    box-sizing: border-box;
    border: 1px solid $colors-grid-separators;
    border-top: none;

    background-color: rgba(0, 0, 0, 0.015);
  }

  table {
    width: 100%;
    font-size: small;
    border-collapse: collapse;

    background-color: $colors-paper-solid;
    table-layout: fixed;

    thead tr th {
      position: relative;
      text-align: center;
      text-transform: uppercase;
      font-size: smaller;
      padding: 0;
      box-sizing: border-box;

      white-space: nowrap;
      text-overflow: ellipsis;

      &:not(:first-child) {
        border-left: 1px solid var(--colors-primary-lighter);
      }
    }

    thead,
    tbody tr {
      width: 100%;
      height: $default-row-height;
    }

    thead {
      tr {
        position: relative;
        border: 1px solid $colors-primary;
      }
    }

    tbody {
      &.noVirtualScroll {
        tr {
          border-bottom: 1px solid $colors-grid-separators;
        }
      }

      &:not(.noVirtualScroll) {
        tr {
          &:not(:first-child) {
            border-bottom: 1px solid $colors-grid-separators;
          }
        }
      }

      tr {
        td {
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          padding: 0;
          box-sizing: border-box;

          &.growableCell {
            text-align: right;

            input {
              background-color: transparent;
              padding: 0 4px;
              margin: 0;
              width: 100%;
              border: none;
              outline: none;
              font-size: $small-font-size;

              &.center {
                text-align: center;
              }
            }
          }

          &:not(:first-child) {
            border-left: 1px dashed $colors-grid-separators;
          }

          cursor: default;

          &.childrenTableContainer {
            padding: 0;

            .container {
              background-color: $colors-alternate-row;

              tr {
                &:nth-child(n) {
                  background-color: rgba(0, 0, 0, 0.045);
                }

                &:nth-child(2n) {
                  background-color: rgba(0, 0, 0, 0.065);
                }

                &:first-child td {
                  box-shadow: inset 0 3px 8px -7px black;
                }
              }
            }
          }
        }

        &:hover {
          background-color: $colors-hover;
        }
      }
    }
  }
}

.menuItem {
  display: flex;
  gap: 8px;
  padding-right: 8px;
  padding-left: 8px;

  .menuItemIcon {
    display: block;
  }
}

.headerContainer {
  position: relative;

  .contentWrapper {
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background-color: $colors-primary;
    }
  }

  .moreIcon {
    position: absolute;
    width: 16px;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    color: white;
  }

  .header {
    text-transform: uppercase;

    .headerCell {
      display: flex;
      align-items: center;
      line-height: 1;

      span {
        margin-right: 8px;
      }
    }
  }

}

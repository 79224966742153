@import 'src/styles/constants';

.container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);

  .sectionTitle {
    padding: 0 16px;
    font-size: $small-font-size;
    text-transform: uppercase;
    font-weight: bold;
  }

  .sectionContent {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px 0;
    height: 100%;
  }
}
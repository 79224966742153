.container {
  padding: 4px;

  .firmName {
    padding: 0;
  }

  .filePath {
    opacity: 0.5;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    font-size: smaller;
  }
}

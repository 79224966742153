@import 'src/styles/constants';
@import 'src/styles/common';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  padding: 0;
}

.actionItem {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 16px;
  width: 100%;
  color: #444;
  padding: 0 24px 0 8px;

  overflow: hidden;

  .label {
  }

  .icon {
    font-size: $default-row-height - 4;
  }
}

.spinner {
  border-radius: 12px;
  border: 3px solid rgba(0, 0, 0, 0.15);
  border-top-color: $colors-primary;
  border-left-color: $colors-primary;
  animation: spin 1s infinite linear;
  box-sizing: border-box;
  margin: 2px;
  width: 12px;
  height: 12px;
}

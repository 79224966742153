@import "src/styles/constants";
@import "src/styles/common";

.form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
  max-width: 800px;

  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  .fieldset {
    border: none;
    border-radius: 8px;
  }
}

.section {
  font-size: 13px;

  h4 {
    margin: 4px 0;
  }
}

.matrix {
  flex: 1;
  overflow-y: auto;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.savingMatrix {
    @include loader();
  }

  .groupAddButton {
    font-size: 13px;
    margin: 8px 0;
    text-align: center;
    color: $colors-primary;

    i, span {
      color: inherit;
      cursor: pointer;
    }

    i {
      margin: 0 4px;
    }
  }

  .assetClassView {
    background: #f8f8f8;
    border-radius: 7px;
    padding: 10px;
    margin: 8px 0;
    flex: 1;

    .assetClassLabel {
      margin-bottom: 6px;
      text-align: center;
      text-transform: uppercase;
    }

    .submitRowNotice {
      margin-left: auto;
      opacity: 0.5;
      font-size: 12px;
      padding: 2px 0 0 0;
    }

    .groupHeading {
      display: flex;
      align-items: center;
      margin: 0 0 8px 0;

      .groupHeadingItem {
        display: flex;
        width: 240px;
        align-items: center;
        gap: 8px;

        .groupHeadingItemLabel {
          width: 40%;
          text-align: right;
        }

        .groupHeadingItemEditor {
          width: 60%;

        }
      }
    }


    .tableHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .assetClassName {
        text-transform: uppercase;
        color: #404040;
        font-size: 12px;
        padding: 0 2px;

      }
    }

    .tableContainer {
      display: flex;
      flex-direction: column;
      margin: 2px 0 0 0;
      height: 200px;
      min-height: 100%;
    }
  }
}

.numericInput {
  text-align: right;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid $colors-grid-separators;
  padding: 6px 0 0 0;
}

.addItemButtonBase {
  width: 100%;
  background-color: white;
  padding-top: 10px;
  text-align: center;

  .addItemButton {
    background-color: $colors-primary;
    color: white;
    padding: 6px;
    border-radius: 3px;

    .addItemButtonContent {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

.inertItem {
  text-align: center;

  &:hover {
    background-color: transparent;
  }
}

.disabledSelectedItem {
  background: white;
}

.addEntryButton {
  color: $colors-primary;

  .addEntryButtonButton {
    border-radius: 3px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
  }
}

.expandButton {
  display: flex;
  align-items: center;
  outline: 1px solid blue;

button {
    border: none;
    width: $default-row-height;
    line-height: $default-row-height;
    padding: 0;
    margin: 0 2px;
    color: $colors-primary-text;
    background: none;
    cursor: pointer;

    &:hover {
      color: $colors-primary;
    }

    i {
      font-size: $default-row-height - 8;
      line-height: inherit;
    }
  }}
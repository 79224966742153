@import 'src/styles/constants';

.inputBox {
  .ipInput {
    text-align: right;
  }

  .portInput {
    border-style: none;
    border-radius: 0;
    border-left-style: dotted;
    max-width: 7ch;
    border-color: lighten($colors-primary-text, 50);
    padding: 0 4px;
    outline: none;
    color: $colors-primary-text;

    &::placeholder {
      color: lighten($colors-primary-text, 50);
    }
  }
}
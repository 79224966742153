@import '../../styles/constants';


.numericCell {
  text-align: center;

  &.negative {
    color: $colors-danger-text;
  }

  &.zero {
    color: $colors-secondary-text;
  }
}

.currencyCell {
  text-align: right;
}

.emptyOrInvalid {
  color: $colors-secondary-text;
}

.symbolCell {
  text-align: center;
}

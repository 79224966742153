@import "src/styles/constants";

.container {
  display: flex;
  flex-direction: column;
  position: absolute;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.narrow {
    width: 40%;
    left: 30%;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  }

  .title {
    margin: 0 0 8px 0;
  }
}

.oktaGroups {
  display: flex;
  gap: 4px;
  padding: 0 4px;

  .oktaGroup {
    font-size: 10px;
    font-weight: 400;
    padding: 2px 4px;
    background: var(--colors-alternate-row);
    border-radius: 4px;
  }
}

.wide {
  min-width: 600px;
}
.container {
  margin: 0 0 0 auto;

  .buttonContent {
    i {
      margin: 0 8px 0 0;
    }
  }
}

.fieldList {
  display: grid;
  flex-wrap: wrap;
  max-width: 400px;

  .fieldItem {
    display: flex;
    justify-content: flex-start;
    padding: 0 16px;
  }
}

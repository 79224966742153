// Dimensions
$application-bar-height: 48px;
$application-bar-width: 248px;
$spacing: 4px;
$medium-font-size: 14px;
$small-font-size: 12px;
$scrollbar-padding: 4px;
// Colors
$colors-success-text: #499472;
$colors-success-background: #e3f8e1;
$colors-danger-text: #f13448;
$colors-danger-background: #ffe4e8;
$colors-warning-text: #f0a809;
$colors-warning-background: #f3e8a0;
$colors-info-text: #1380f1;
$colors-info-background: #e3e8f1;
$colors-primary-text: #444;
$colors-primary: #0989f1;
$colors-primary-light: lighten($colors-primary, 30);
$colors-primary-lighter: lighten($colors-primary-light, 30);
$colors-primary-dark: darken($colors-primary, 30);
$colors-primary-foreground: #444;
$colors-primary-foreground-light: lighten(lighten($colors-primary-foreground, 90), 60);
$colors-paper: #fff;
$colors-paper-solid: #fff;
$colors-desaturated-primary: desaturate($colors-primary, 40);
$colors-paper-dark: rgba($colors-desaturated-primary, 0.025);
$colors-hover: rgba($colors-primary, 0.05);
$colors-contrast-text: #fff;
$colors-secondary-text: desaturate($colors-primary, 80);
$colors-secondary-text-light: lighten($colors-secondary-text, 30);
$colors-secondary-text-dark: darken($colors-secondary-text, 20);
$colors-secondary: #10c0f0;
$colors-secondary-light: #15c6f8;
$colors-secondary-dark: #08a8d9;
$colors-secondary-foreground: #bbb;
$colors-secondary-foreground-light: #f1f1f1;
$colors-accent-text: #f08100;
$colors-accent-background: #c8c001;
$colors-accent-light: #e0a100;
$colors-accent-dark: #a89130;
$colors-accent-foreground: #444;
$colors-grid-separators: rgba(0, 0, 0, 0.125);
$colors-grid-separators-inverted: rgba(255, 255, 255, 0.45);
$colors-alternate-row: rgba($colors-primary-lighter, 0.15);
// Dimensions
$default-modal-width: 420px;

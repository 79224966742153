.center {
  text-align: center;
}

.cell {
  .unknown {
    text-align: center;
    color: crimson;
  }

  .content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.text {
      padding: 0 4px;
    }
  }
}

.expanderCell {
  .container {
    display: flex;
    align-items: center;
  }
}

.container {

  .expander {
    flex-basis: 0;

    &:not(:empty) {
      padding: 0;
      flex-basis: 24px;
      height: 24px;

      line-height: 24px;
      text-align: center;

      border-radius: 12px;
      overflow: hidden;

      margin: 0 8px 0 0;

      i {
        line-height: 1;
      }
    }
  }

  .content {
    flex: 1;
  }
}

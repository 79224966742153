@import '../../styles/constants';

.container {
  min-width: min-content;

  .fullHeight {
    height: 100vh;
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .titleBar {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    display: flex;
    text-transform: uppercase;
    padding: 4px;
    color: $colors-secondary-text-dark;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-width: min-content;

    align-items: center;

    .titleText {
      font-size: 12px;
      font-weight: 900;
      padding: 0 8px;
    }

    .closeButton {
      font-size: 15px;
      margin-left: auto;
      cursor: pointer;

      &:hover {
        color: $colors-danger-text;
      }
    }
  }

  .content {
  }
}

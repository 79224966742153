@import 'src/styles/constants';

.applicationItem {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 2px;

  .applicationName {}

  .applicationProduct {
    color: $colors-secondary-text;
  }
}

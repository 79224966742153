.smRuleInput {
  .content {
    display: flex;
    align-items: center;
    gap: 4px;

    :not(label) {
      &.inputBox {
        flex: 1 0;
      }

      &.refreshButton {
        flex: 0 0;
        flex-basis: 22px;
        min-width: 0;
        width: 22px;
        height: 22px;
        line-height: 22px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 2px;
      }
    }
  }
}
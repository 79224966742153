@import "src/styles/constants";

.entity {
  display: flex;
  align-items: center;
  padding: 2px;

  &:not(:last-child) {
    border-bottom: 1px dashed $colors-grid-separators;
  }

  .radioButton {
    margin: 0;
  }

  .text {
    .entityTitle {
      font-weight: 600;
    }

    .entityId {
      color: $colors-secondary-text;
      font-weight: 400;
    }
  }

  .defaultIndicator {
    font-size: 12px;
    padding: 3px 24px;
    text-transform: uppercase;
    color: $colors-accent-text;
  }
}

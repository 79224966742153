@import '../../styles/constants';

$border-radius: 4px;

.container {
  border-top: 1px dashed #e8e8e8;
  padding: 1px 4px 4px 4px;

  h4 {
    font-size: small;
    color: #888;
    padding: 1px 1px 0 1px;
    margin: 0;
  }

  .table {
    margin: 3px 0 0 0;
    border-spacing: 0;
    font-size: smaller;
    width: 100%;
    border-radius: $border-radius;

    thead {
      tr th {
        padding: 2px;
        font-size: x-small;
        color: #888;
        font-weight: bold;
      }
    }

    tbody {
      tr {
        &:first-child {
          td:first-child {
            border-top-left-radius: $border-radius;
            border: 1px solid $colors-grid-separators;
            border-right: inherit;
          }

          td:last-child {
            border-top-right-radius: $border-radius;
            border: 1px solid $colors-grid-separators;
            border-left: inherit;
          }
        }

        td:first-child {
          border-left: 1px solid $colors-grid-separators;
        }

        td:last-child {
          border-right: 1px solid $colors-grid-separators;
        }

        &:last-child {
          td:first-child {
            border-bottom-left-radius: $border-radius;
            border: 1px solid $colors-grid-separators;
            border-top: none;
            border-right: none;
          }

          td:last-child {
            border-bottom-right-radius: $border-radius;
            border: 1px solid $colors-grid-separators;
            border-top: none;
            border-left: none;
          }
        }

        &:first-child {
          td {
            border-top: 1px solid $colors-grid-separators;
          }
        }

        td {
          padding: 0 2px;
          border-bottom: 1px solid $colors-grid-separators;

          &:nth-child(2) {
            border-left: 1px dashed $colors-grid-separators;
          }

          &:nth-child(n + 2):not(:last-child) {
            border-right: 1px dashed $colors-grid-separators;
          }

          &.number {
            text-align: right;
          }

          &.string, &.date {
            text-align: center;
          }
        }
      }
    }
  }
}
@use "sass:math";
@use "sass:color";

@import "../../styles/constants";

$border-radius: math.div(3 * $application-bar-height, 8);

@mixin linkItem {
  display: block;
  color: inherit;
  text-decoration: none;
  font-weight: inherit;
  width: 100%;
  padding: 0 12px;
  cursor: default;
  line-height: inherit;

  &:hover {
    color: $colors-primary;
  }
}

.container {
  position: fixed;

  left: 0;
  top: 0;
  width: $application-bar-width;
  bottom: 0;
  background-color: #f1f1f1;

  border-right: 1px solid rgba(0, 0, 0, 0.075);

  .title {
    text-align: center;
    width: 100%;
    padding: 16px;
    margin: 0 auto;

    img {
      max-width: 90%;
      padding: 0;
    }
  }

  .menu {
    padding: 0;
    overflow: hidden;
    margin: 24px 0;
    font-size: 14px;

    li {
      list-style: none;
      cursor: pointer;

      a {
        padding: 12px 16px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .dropdown {

        i {
          margin: 0 0 0 8px;
        }

        ul {
          margin: 0;
          padding: 0;

          li {
            padding: 0;
          }
        }

        .sectionHeader {
          padding: 12px 8px;
          text-transform: uppercase;
          opacity: 0.75;
          font-size: 0.8em;
          cursor: default;
          font-weight: bold;
        }
      }

      a {
        display: block;
        color: inherit;
        text-decoration: none;
        height: 100%;
        margin: 0;

        &:hover {
          background-color: #f1f1f1;
        }

        &.active {
          color: $colors-primary-dark;
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
  }

  .userButton {
    width: 100%;
    cursor: pointer;


    .buttonTitle {
      display: flex;
      align-items: center;
      width: 100%;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: rgba(0, 0, 0, 0.075);
      padding: 8px 0;
      background-color: #f8f8f8;

      .userName {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 4px 8px 4px 0;
        overflow: hidden;

        .title {
          text-align: left;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .subTitle {
          color: $colors-secondary-text;
          text-align: left;
          padding: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      i {
        display: block;

        &.arrow {
          margin-left: 0;
        }

        &:first-child {
          margin: 0 8px;
        }

        &:last-child {
          margin: 0 8px 0 auto;
        }

        &.large {
          font-size: 2em;
        }
      }
    }
  }
}

.menuItem {
  list-style: none;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $colors-secondary-text;

  .menuItemIcon {
    margin: 0 8px 0 0;
  }

  &.signOut {
    color: crimson;
  }

  &.versionNumber {
    font-size: smaller;
    padding: 8px 22px 0 22px;
    color: $colors-secondary-text-light;
    border-top: 1px solid lighten($colors-secondary-text-light, 15);
  }
}


@import '../../../../../../../styles/constants';

.container {
  padding: 2px 0;

  .mpid {
    line-height: 1;
  }

  .applicationName {
    font-size: $small-font-size;
    color: $colors-secondary-text;
  }
}

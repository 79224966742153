@use 'sass:math';
@import "../../../../styles/common";
@import '../../../../styles/constants';

.cell {
  text-align: center;
  padding: 0 4px;

  &:not(.never) {
    display: flex;
    justify-content: center;
    padding: 3px 0 0 0;
  }

  .date {
    font-size: smaller;
    margin: 0 2px 0 0;
  }

  .time {
    font-size: smaller;
    color: $colors-secondary-text-dark;
    margin: 0 0 0 2px;
  }

  &.never {
    color: $colors-secondary-text;
  }
}

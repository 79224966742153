.message {
  display: flex;
  text-align: left;
  padding: 0 4px;

  .type {
    font-size: 0.75em;
    padding: 2px 4px;
    background-color: #526676;
    border-radius: 4px;
    color: white;
    font-weight: 600;
  }

  .json {
    display: flex;
    align-items: center;
    font-size: 10px;

    .jsonEntry {
      margin: 0 4px;

      .key {
        &::after {
          content: ': ';
        }
      }

      .value {
        color: #666;
      }
    }
  }

  .fixMessageType {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 0 8px;

    span {
      font-weight: 400;
    }

    .error {
      color: crimson;
    }

    .new {
      color: goldenrod;
    }

    .success {
      color: seagreen;
    }

    .info {
      color: #667F94;
    }
  }

  .fixMessageDetails {
    display: flex;
    align-items: center;
    gap: 3px;

    .BUY, .SELL {
      padding: 0 4px;
      border-radius: 4px;
      width: 2.5em;
      text-align: center;
      margin: 0 4px 0 0;

      span {
        font-weight: bold;
        font-size: 10px;
      }
    }

    .BUY {
      color: seagreen;
      background-color: rgba(seagreen, 0.1);
    }

    .SELL {
      color: crimson;
      background-color: rgba(crimson, 0.1);
    }

    div {
      font-weight: 400;
      color: #333;
    }
  }

  .senderTarget {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 8px;

    div {
      font-weight: 500;
    }
  }

  .viewDetailsButton {
    display: flex;
    align-items: center;

    .viewDetailsButtonRoot {
      padding: 2px;
      height: auto;
      line-height: initial;
      font-size: 10px;
    }
  }
}

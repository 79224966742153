.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  .wrapper {
    display: flex;
    width: 30%;

    .icon {
      font-size: 100px;
    }

    .text {
      width: 100%;

      h1, h2, h3 {
        width: 100%;
        padding: 0 16px;
      }

      h1 {
        margin: 0;
      }

      h2 {
        font-size: 20px;
        font-weight: 400;
        margin: 0;
      }

      h3 {
        opacity: 0.75;
        font-weight: 400;
        font-size: 18px;
        margin: 16px 0;
        border-radius: 5px;
        padding: 24px 16px;
      }
    }
  }
}
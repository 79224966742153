@import "../../styles/constants";

.container {
  position: relative;
  min-width: 400px;
  overflow: auto;
  height: 100%;

  &:not(.embedded) {
    padding: 8px 12px;
  }

  form, &.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 4px;
  }

  .fieldsetComponent {
    padding: 0;
    margin: 0;
    border: none;
  }

  .error {
    position: relative;
    display: inline-block;
    font-weight: 500;
    padding: 2px 3px;
    border-radius: 3px;
    background-color: crimson;
    box-shadow: 0 2px 4px -2px black;
    font-size: smaller;
    color: white;

    .errorContent {
      position: relative;
      display: flex;
      padding: 1px 2px;
      gap: 4px;
      align-items: center;
      line-height: 1em;
      z-index: 1;

      .decorationIcon {
        flex-grow: 0;
        width: 1em;
        height: 1em;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: -4px;
      left: 8px;
      transform: rotate(45deg);
      background-color: crimson;
      z-index: 0;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 4px 0 0 0;

    button {
      &:not(:first-child) {
        margin: 4px 0 0 4px;
      }
    }

    i {
      display: block;
      margin-right: 4px;
      font-size: smaller;
    }

    .stillErrorsNotice {
      font-size: 12px;
      font-weight: 500;
      padding: 0 8px;
      color: $colors-secondary-text;
    }
  }

  .fieldset {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 0;
    margin: 0;

    legend {
      margin: 0 0 4px 0;
    }

    &.wrapper {
      border: none;
    }

    .fieldDescription {
      font-size: $small-font-size;
      font-weight: normal;
      padding: 0 3px;
    }

    .field .label {
      white-space: pre-wrap;
    }

    .field .control {
      position: relative;
    }

    .field.numeric input {
      text-align: right;
    }

    .field.group {
      display: grid;
      align-items: flex-start;
      gap: 12px;
      margin: 8px 0;

      &1 {
        grid-template-columns: 1fr;
      }

      &2 {
        grid-template-columns: 1fr 1fr;
      }
    }

    .mappedGroup {
      display: flex;
      font-size: $small-font-size;
      padding: 8px;
      gap: 8px;

      .mappedGroupItemList {
        width: 150px;
        color: $colors-primary-text;
        cursor: pointer;
        border: 1px solid $colors-grid-separators;
        border-radius: 3px;

        .selected {
          background: rgba($colors-secondary-text, 0.15);
        }
      }

      .mappedGroupFieldsContainer {
        display: flex;
        flex: 1;
        overflow: hidden;
        width: 400px;

        .mappedGroupFields {
          flex-grow: 1;
          flex-shrink: 0;
          width: 100%;
          visibility: hidden;
          transform: none;

          &.visible {
            visibility: visible;
          }
        }
      }
    }

    .custom {
      min-height: 1.7em;
    }

    .multiBoolean {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 4px 12px;
      padding: 4px;

      .multiBooleanItem {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        label {
          flex: 1;
        }
      }
    }

    .fieldset {
      display: flex;
      flex-direction: column;
      gap: 3px;
      border: none;
      padding: 0;
      color: lighten($colors-secondary-text, 10);

      &.arrayContainer {
        position: relative;

        legend span {
          display: inline;
        }

        .array {
          max-height: 17em;
          overflow-y: auto;
          color: $colors-primary-text;
          font-size: 14px;

          .arrayItem {
            display: flex;
            align-items: center;
            padding: 0 4px;
            cursor: default;
            line-height: 1.5;
            border-radius: 4px;

            .itemContent {
              flex: 1;
            }

            &:hover {
              background-color: $colors-hover;
            }

            .buttonsContainer {
              display: flex;
              align-items: center;

              .deleteButton,
              .editButton {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 22px;
                height: 22px;
                background-color: rgba(0, 0, 0, 0.075);

                svg {
                  height: 16px;
                  width: 16px;
                  margin: 2px;
                }

                &:hover {
                  color: $colors-contrast-text;
                  background-color: rgba($colors-primary, 0.75);
                }
              }

              :first-of-type {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
              }

              :last-of-type {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
              }

              .deleteButton {
                color: $colors-danger-text;
              }
            }
          }
        }
      }

      fieldset legend {
        width: auto;
      }

      legend {
        display: flex;
        align-items: center;
        gap: 8px;

        width: 100%;

        font-size: 13px;
        text-transform: uppercase;
        color: inherit;

        span {
          display: block;
        }

        .legendSeparator {
          flex: 1;
          height: 1px;
          background-color: $colors-grid-separators;
        }

        a {
          color: $colors-primary;
          display: flex;
          align-items: center;
          vertical-align: middle;
          border: none;
          padding: 0 4px;
          border-radius: 6px;
          gap: 4px;
          text-transform: uppercase;
          background-color: rgba($colors-primary, 0.05);

          .icon {
            width: 15px;
          }
        }
      }
    }

    .field {
      display: flex;
      align-items: center;

      .pickOne {
        color: $colors-secondary-text-light;
        background-color: transparent;
      }

      label {
        line-height: 1;
        flex: 3;
        text-align: start;
        padding: 0 0 0 8px;
      }

      :not(label) {
        flex: 4;
      }
    }
  }
}

@import '../../../../styles/constants';
@import '../../../../styles/common';

.cell {
  position: relative;
  box-sizing: border-box;
  background-color: $colors-primary;
  cursor: grab;

  .container {
    color: $colors-contrast-text;

    .resizeHandle {
      position: absolute;

      top: 0;
      bottom: 0;
      right: -3px;
      width: 5px;
      cursor: col-resize;

      z-index: 1;

      &:hover {
        background-color: rgba($colors-contrast-text, 0.45);
      }
    }

    .label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $default-row-height;
      text-align: left;
      padding: 0 4px;

      .labelContent {
        flex: 1;
        padding: 0;
        line-height: 16px;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .sortHandle {
        cursor: pointer;

        &:not(.isSorted) {
          opacity: 0.5;
        }
      }
    }

    .filter {
      height: $default-row-height;

      input {
        width: 100%;
        margin: 0;
        border: none;
        padding: 0 4px;
        line-height: $default-row-height;
        outline: none;
        background-color: transparent;
        border-top: 1px dashed $colors-grid-separators-inverted;
        color: $colors-contrast-text;

        &::placeholder {
          color: $colors-grid-separators-inverted;
        }
      }
    }
  }
}


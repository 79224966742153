@import "../../styles/constants";
@import "../../styles/common";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: $default-row-height;

  .button {
    text-align: center;
    background: none;
    border: none;
    border-radius: 40px;
    margin: 0 2px;

    .edit, .delete {
      font-size: $default-row-height - 4;
      line-height: 1;
    }

    .edit {
      color: $colors-primary-text;
    }

    .delete {
      color: $colors-primary-text;
    }
  }
}
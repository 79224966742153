@use 'sass:math';
@import '../../../../styles/constants';

$button-size: 24px;

.container {
  border-radius: math.div($button-size, 2);
  overflow: hidden;

  &:not(:first-child) {
    margin: 0 0 0 12px;
  }

  .button {
    display: flex;
    align-items: center;
    font-size: 15px;
    height: $button-size;
    line-height: $button-size;

    background-color: $colors-alternate-row;
    width: 120px;

    &:not(.selected) {
      &.info {
        background-color: $colors-info-background;
        color: $colors-info-text;
      }

      &.success {
        background-color: $colors-success-background;
        color: $colors-success-text;
      }

      &.warning {
        background-color: $colors-warning-background;
        color: $colors-warning-text;
      }

      &.danger {
        background-color: $colors-danger-background;
        color: $colors-danger-text;
      }

      &.inactive {
        color: $colors-secondary-text;
      }
    }

    &.selected {
      color: white;
      background-color: $colors-primary;
    }

    .label {
      flex: 1;
      padding: 0 12px;
      text-align: left;
    }

    .value {
      flex-basis: 40px;
      font-weight: 600;
      padding: 0 8px;
    }
  }
}

.container {
  display: inline-block;
  color: white;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 11px;
  padding: 1px 8px;

  cursor: pointer;

  &.disabled {
    background-color: #919191;
    opacity: 0.5;
  }

  &.error:not(.disabled) {
    background-color: crimson;
  }

  &.warning:not(.disabled) {
    background-color: goldenrod;
  }

  &.info:not(.disabled) {
    background-color: #0660A9;
  }

  &.unknown:not(.disabled) {
    color: #919191;
  }
}

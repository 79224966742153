.container {
  padding: 10px 20px;

  .titleContent {
    display: flex;
    gap: 20px;

    .text {
      h4 {
        font-size: 18px;
        font-weight: bold;
        margin: 0;
      }

      p {
        margin: 4px 0;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0 0;
  }
}
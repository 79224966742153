@import "../../styles/constants";

.container {
  position: absolute;

  left: $application-bar-width;
  top: 0;
  right: 0;
  bottom: 0;

  .content {
    position: absolute;
    top: 2 * $spacing;
    bottom: 2 * $spacing;
    left: 4 * $spacing;
    right: 4 * $spacing;
  }
}

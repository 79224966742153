:root {
  --application-bar-height: 48px;
  --application-bar-width: 248px;
  --spacing: 4px;
  --medium-font-size: 14px;
  --small-font-size: 12px;
  --scrollbar-padding: 4px;
  --colors-success-text: #499472;
  --colors-success-background: #e3f8e1;
  --colors-danger-text: #f13448;
  --colors-danger-background: #ffe4e8;
  --colors-warning-text: #f0a809;
  --colors-warning-background: #f3e8a0;
  --colors-info-text: #1380f1;
  --colors-info-background: #e3e8f1;
  --colors-primary-text: #444;
  --colors-primary: #0989f1;
  --colors-primary-light: #4DABF8;
  --colors-primary-lighter: #ABD7FC;
  --colors-primary-dark: #0660A9;
  --colors-primary-foreground: #444;
  --colors-primary-foreground-light: #CECECE;
  --colors-paper: #fff;
  --colors-paper-solid: #fff;
  --colors-desaturated-primary: #3784C3;
  --colors-paper-dark: #3784c306;
  --colors-hover: #0989f10c;
  --colors-contrast-text: #fff;
  --colors-secondary-text: #667F94;
  --colors-secondary-text-light: #91A4B4;
  --colors-secondary-text-dark: #526676;
  --colors-secondary: #10c0f0;
  --colors-secondary-light: #15c6f8;
  --colors-secondary-dark: #08a8d9;
  --colors-secondary-foreground: #bbb;
  --colors-secondary-foreground-light: #f1f1f1;
  --colors-accent-text: #f08100;
  --colors-accent-background: #c8c001;
  --colors-accent-light: #e0a100;
  --colors-accent-dark: #a89130;
  --colors-accent-foreground: #444;
  --colors-grid-separators: #0000001f;
  --colors-grid-separators-inverted: #ffffff72;
  --colors-alternate-row: #abd7fc26;
  --default-modal-width: 420px;
}

@import "../../styles/constants";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .title {
    margin: 8px 0 16px 0;
  }

  .formContainer {
    display: flex;
    align-items: center;

    .toolbarContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      flex: 1;
      height: 100%;

      .toolbarFooter {
        position: relative;
        padding: 8px;
      }
    }

    .form {
      display: grid;
      width: 50%;
      padding: 16px;
      flex: 1;

      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 24px);
      grid-gap: 4px $spacing;

      .field {
        display: flex;
        align-items: center;

        >:not(.label) {
          flex: 1;
          font-size: $medium-font-size;
        }

        .label {
          flex: 0.5;
          margin-right: 2 * $spacing;
          text-align: right;
        }
      }

      .resultsCounter {
        grid-column: 1 / 4;
        text-align: right;
        color: $colors-secondary-text-dark;
        padding: 0 8px;
        font-weight: 500;
      }

      .searchContainer {
        text-align: right;
        padding: 0;

        button {
          height: 24px;
        }
      }
    }
  }

  .content {
    flex: 1;

    margin: 0 0 8px 0;
  }
}
